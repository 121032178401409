// vendors
import styled from 'styled-components';
import { rem } from 'polished';

// images
import LogoBouillonPrice from '@images/LogoBouillonPrice';

export const breakpoints = [824, 1120];

export const Content = styled.div`
  display: grid;
  grid-gap: ${rem(60, 18)};

  margin-bottom: 3em;
`;

export const LogoPrice = styled(LogoBouillonPrice)`
  position: sticky;
  top: 140px;

  width: 100%;
  max-width: ${rem(300)};
`;

export const MenuIllustration = styled.img`
  display: block;
`;
