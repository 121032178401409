// vendors
import React from 'react';
import { hideVisually, rem } from 'polished';

// styles
import { styles, utils, SectionContainer } from '@pied-bleu/shared-ui';

// components
import Layout from '@components/Layout';

// views
import LocationsSection from '@views/LocationsSection';
import MenuSection from '@views/MenuSection';

const IndexPage = () => {
  return (
    <Layout>
      <SectionContainer bgColor={styles.colors.primary} borderPatternBottom>
        <h2 css={hideVisually}>Définition</h2>

        <p
          css={`
            max-width: 90%;
            margin: ${rem(32)} auto;
            color: ${styles.colors.white};
            font-weight: 700;
            font-size: 2.25rem;
            text-align: center;
            text-transform: uppercase;

            ${utils.breakpointsRange(
              [
                {
                  prop: 'fontSize',
                  sizes: [20, 40],
                  bases: [16, 18],
                  unit: 'rem',
                },
              ],
              [480, 1120]
            )};
          `}
        >
          Un bouillon est un type de restaurant servant une cuisine française
          traditionnelle, notamment{' '}
          <span
            css={`
              color: ${styles.colors.secondary};
            `}
          >
            le bouillon{' '}
          </span>
          qui a donné son nom à ces établissements. Le concept est de servir des
          aliments de bonne qualité rapidement et à des prix abordables.
        </p>
      </SectionContainer>

      <MenuSection />

      <LocationsSection />
    </Layout>
  );
};

export default IndexPage;
