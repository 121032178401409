// vendors
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { hideVisually } from 'polished';

// styles
import { styles, Location, SectionContainer } from '@pied-bleu/shared-ui';

const Locations = () => {
  const data = useStaticQuery(graphql`
    query Location {
      locations: allSanityLocation(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            name
            openingHours {
              day
              hours {
                fromTime {
                  hour
                  minute
                }
                toTime {
                  hour
                  minute
                }
                note
              }
            }
          }
        }
      }
    }
  `);

  const locations = (data.locations.edges || []).map(({ node }) => node);

  return (
    <SectionContainer bgColor={styles.colors.primary}>
      <h2 css={hideVisually}>Où nous trouver</h2>

      <div
        css={`
          ${styles.layouts.grid('300px', '20px', locations.length)}

          color: ${styles.colors.secondary};
          text-transform: uppercase;
        `}
      >
        {locations.map((location, index) => (
          <Location
            key={location.name}
            inverted
            indexTitle={index}
            place={location.name}
            openingHours={location.openingHours}
          />
        ))}
      </div>
    </SectionContainer>
  );
};

export default Locations;
