// vendors
import styled from 'styled-components';
import { rem } from 'polished';

// styles
import { styles, utils } from '@pied-bleu/shared-ui';

export const List = styled.ul`
  ${styles.global.unstyledListStyle}

  ${styles.layouts.stack(rem(48, 18))};
`;

export const ListItem = styled.li``;

export const ListTitle = styled.h3`
  margin-bottom: 12px;

  color: ${styles.colors.primary};
  font-weight: ${styles.typography.fontWeights.bold};
  font-size: 1.5rem;
  line-height: ${styles.typography.lineHeights.heading};
  text-transform: uppercase;

  ${utils.mediaQuery.lessThan(480)} {
    font-size: 1.25rem;
  }

  ${utils.mediaQuery.lessThan(321)} {
    font-size: 1rem;
  }
`;

export const SubList = styled.ul`
  ${styles.global.unstyledListStyle}

  ${styles.layouts.stack('8px')}
`;

export const SubListItem = styled.li`
  color: ${styles.colors.white};
  font-weight: ${styles.typography.fontWeights.bold};
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: 1px;
  text-transform: uppercase;

  ${utils.mediaQuery.lessThan(480)} {
    font-size: 1rem;
  }

  ${utils.mediaQuery.lessThan(321)} {
    font-size: 0.875rem;
  }
`;
