// vendors
import React from 'react';
// import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { hideVisually, rem } from 'polished';

// images
import illustrationAmbiance from '@images/illustrationAmbiance.svg';
import illustrationCuisine from '@images/illustrationCuisine.svg';

// styles
import { styles, utils, SectionContainer } from '@pied-bleu/shared-ui';

// components
import Menu from '@components/Menu';

import {
  breakpoints,
  Content,
  // LogoPrice,
  MenuIllustration,
} from './MenuSection.styles';

const MenuSection = () => {
  const data = useStaticQuery(graphql`
    query MenuItems {
      menuItems: allSanityRestaurantMenuItem(
        sort: { fields: sort, order: ASC }
      ) {
        nodes {
          category {
            sort
            price
            name
            id
          }
          name
          price
          sort
          id
        }
      }
    }
  `);

  const nodes = data.menuItems.nodes || [];

  const menuItems = nodes.reduce((acc, item) => {
    if (Object.keys(acc).includes(item.category.name)) return acc;

    acc[item.category.name] = {};

    Object.assign(acc[item.category.name], item.category);

    acc[item.category.name].products = nodes.filter(
      (n) => n.category.id === item.category.id
    );

    return acc;
  }, {});

  return (
    <SectionContainer id='menu' bgColor={styles.colors.secondary}>
      <h2 css={hideVisually}>Menu</h2>

      <Content>
        {/* <div
          css={`
            grid-column: 1;
            grid-row: 1;

            margin-top: 2em;

            max-width: 200px;

            ${utils.mediaQuery.greaterThan(breakpoints[0])} {
              margin-top: 2em;

              width: 100%;
              min-width: 200px;
              max-width: ${rem(300)};
            }

            ${utils.mediaQuery.greaterThan(breakpoints[1])} {
              grid-row: 1 / span 2;
            }

            ${utils.mediaQuery.lessThan(breakpoints[0])} {
              justify-self: center;
            }
          `}
        >
          <LogoPrice />
        </div> */}

        <div
          css={`
            grid-column: 1;
            grid-row: 2;

            ${utils.mediaQuery.greaterThan(breakpoints[0])} {
              grid-column: 2;
              grid-row: 1;
              min-width: 60ch;

              margin-top: 4em;
            }

            ${utils.mediaQuery.greaterThan(breakpoints[1])} {
              grid-row: 1 / span 2;
            }
          `}
        >
          <Menu items={menuItems} />
        </div>

        <MenuIllustration
          src={illustrationAmbiance}
          alt=''
          role='presentation'
          css={`
            grid-column: 3;
            grid-row: 1;

            justify-self: center;
            align-self: center;

            min-width: 200px;
            max-width: ${rem(300)};
            width: 100%;

            ${utils.mediaQuery.lessThan(breakpoints[1])} {
              display: none;
            }
          `}
        />

        <MenuIllustration
          src={illustrationCuisine}
          alt=''
          role='presentation'
          css={`
            grid-column: 3;
            grid-row: 2;

            justify-self: center;
            align-self: center;

            min-width: 200px;
            max-width: ${rem(300)};
            width: 100%;

            ${utils.mediaQuery.lessThan(breakpoints[1])} {
              grid-column: 1 / span 2;
              grid-row: 2;

              max-width: 200px;
            }

            ${utils.mediaQuery.lessThan(breakpoints[0])} {
              grid-column: 1;
              grid-row: 3;
            }
          `}
        />
      </Content>
    </SectionContainer>
  );
};

export default MenuSection;
