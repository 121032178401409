// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import { List, ListItem, ListTitle, SubList, SubListItem } from './Menu.styles';

const Menu = ({ items }) => {
  const formatPrice = (price) => {
    return price.toFixed(2).replace('.', ',');
  };

  return (
    <List>
      {Object.entries(items).map(([category, values]) => (
        <ListItem key={values.id}>
          <ListTitle>
            {category} {values.price ? `${formatPrice(values.price)}$` : ''}
          </ListTitle>

          <SubList>
            {values.products.map((product) => (
              <SubListItem key={product.id}>
                {product.name}{' '}
                {product.price ? `${formatPrice(product.price)}$` : ''}
              </SubListItem>
            ))}
          </SubList>
        </ListItem>
      ))}
    </List>
  );
};

Menu.propTypes = {
  items: PropTypes.shape({}).isRequired,
};

export default Menu;
