import React from 'react';
import { lighten } from 'polished';

import { styles } from '@pied-bleu/shared-ui';

const LogoBouillonPrice = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 119.39 119.39'
    {...props}
  >
    <title>Entrée + plat + dessert 15 dollars</title>
    <g>
      <circle
        cx='59.69'
        cy='59.69'
        r='59.69'
        fill={lighten(0.08, styles.colors.primary)}
      />
      <g>
        <path
          d='M108.85,58.62a.49.49,0,0,1-.51.48.5.5,0,0,1-.48-.51v-.21a0,0,0,0,0,0,0h-.59s0,0,0-.05v-.24a0,0,0,0,1,.05,0l1.56,0a0,0,0,0,1,.05.05Zm-.32-.22s0,0,0,0h-.29s0,0,0,0v.21a.17.17,0,1,0,.33,0Z'
          fill='#fff'
        />
        <path
          d='M107.18,60a0,0,0,0,1-.05,0l0-.23a0,0,0,0,1,0,0l1.56.09a.07.07,0,0,1,0,.05v.24a.05.05,0,0,1-.05,0Z'
          fill='#fff'
        />
        <path
          d='M107.4,61.17s0,0,0,0l-.05.56a0,0,0,0,1,0,0l-.24,0s0,0,0-.05l.08-.87s0,0,.05,0l1.56.14s0,0,0,0l-.08.86s0,0,0,0l-.23,0a0,0,0,0,1,0-.05l0-.55s0,0,0,0l-.28,0s0,0,0,0l0,.53s0,0,0,0l-.23,0a0,0,0,0,1,0,0l0-.53,0,0Z'
          fill='#fff'
        />
        <path
          d='M108.48,63.23a.54.54,0,0,1-.59.45,2.55,2.55,0,0,1-.61-.08.56.56,0,0,1-.45-.59l.07-.49s0-.05,0,0l1.55.2s0,0,0,.06Zm-.31-.23s0,0,0,0l-.94-.12s0,0,0,0l0,.19a.2.2,0,0,0,.17.21,2.41,2.41,0,0,0,.6.08.19.19,0,0,0,.22-.16Z'
          fill='#fff'
        />
        <path
          d='M107.62,65.52a.48.48,0,0,1-.27-.17,0,0,0,0,0,0,0,.44.44,0,0,1-.34.08.46.46,0,0,1-.42-.56l.09-.55s0,0,.06,0l1.54.27s0,0,0,0l-.1.55A.47.47,0,0,1,107.62,65.52Zm-.07-.5a.17.17,0,0,0,.13.18.14.14,0,0,0,.17-.13l.05-.24s0,0,0,0l-.26,0a0,0,0,0,0,0,0Zm-.28-.3s0,0,0,0l-.3,0s0,0,0,0l0,.24a.17.17,0,0,0,.14.2.19.19,0,0,0,.21-.14Z'
          fill='#fff'
        />
        <path
          d='M106.61,66.39l0,0-.1.49a0,0,0,0,1-.06,0l-.23-.05s0,0,0,0l.17-.79a.05.05,0,0,1,.06,0l1.53.33a0,0,0,0,1,0,0l0,.24a0,0,0,0,1,0,0Z'
          fill='#fff'
        />
        <path
          d='M106.26,67.9s0,0,0,0l-.14.55a0,0,0,0,1,0,0l-.23-.06s0,0,0-.06l.22-.84a0,0,0,0,1,.05,0l1.52.38a0,0,0,0,1,0,.06l-.21.83s0,0-.06,0l-.23-.06a0,0,0,0,1,0-.06l.14-.53s0,0,0,0l-.28-.07s0,0,0,0l-.13.52a0,0,0,0,1,0,0l-.23,0a.06.06,0,0,1,0-.06l.13-.52s0,0,0,0Z'
          fill='#fff'
        />
        <path
          d='M105.74,70.3a.54.54,0,0,1-.34-.69.53.53,0,0,1,.66-.39l1.07.32a0,0,0,0,1,0,.06l-.07.23a0,0,0,0,1-.06,0L106,69.54a.2.2,0,0,0-.24.17.2.2,0,0,0,.12.27l1.06.32s0,0,0,0l-.07.23a.06.06,0,0,1-.06,0Z'
          fill='#fff'
        />
        <path
          d='M105,71.15s0,0,0-.06l.09-.27a0,0,0,0,1,.06,0l.27.09s0,0,0,.06l-.09.27a.06.06,0,0,1-.06,0Z'
          fill='#fff'
        />
        <path
          d='M104.81,73a.53.53,0,0,1-.31-.72.55.55,0,0,1,.68-.34,2.8,2.8,0,0,1,.63.23.56.56,0,0,1,.29.71.53.53,0,0,1-.7.34h0s0,0,0-.06l.08-.23a0,0,0,0,1,.06,0h0a.22.22,0,0,0,.27-.15.21.21,0,0,0-.1-.28,3.56,3.56,0,0,0-.62-.23.21.21,0,0,0-.26.15.23.23,0,0,0,.11.29h0a0,0,0,0,1,0,.06l-.08.22a0,0,0,0,1-.06,0Z'
          fill='#fff'
        />
        <path
          d='M104.72,75a3,3,0,0,1-.61-.26.56.56,0,0,1-.27-.72.57.57,0,0,1,.7-.32,3.29,3.29,0,0,1,.61.26.56.56,0,0,1-.43,1Zm-.49-.56a3.35,3.35,0,0,0,.62.25.2.2,0,0,0,.26-.14.21.21,0,0,0-.09-.28,3.29,3.29,0,0,0-.61-.26.21.21,0,0,0-.26.14A.21.21,0,0,0,104.23,74.42Z'
          fill='#fff'
        />
        <path
          d='M103.58,76.07a0,0,0,0,0,0,0l.83.67s0,0,0,.06l-.15.32a0,0,0,0,1-.06,0l-1.42-.67a0,0,0,0,1,0-.06l.09-.19a0,0,0,0,1,.06,0l.81.38s0,0,0,0L103,76a.06.06,0,0,1,0-.07l.08-.16a0,0,0,0,1,.06,0l.92.17s0,0,0,0l-.8-.38a.05.05,0,0,1,0-.07l.09-.18a.05.05,0,0,1,.07,0l1.41.67a0,0,0,0,1,0,.06l-.15.32a.05.05,0,0,1-.05,0Z'
          fill='#fff'
        />
      </g>
      <g>
        <path
          d='M61,25.52a.12.12,0,0,0,.06.16l2.59,1.18a.23.23,0,0,1,.12.31l-.49,1.09a.23.23,0,0,1-.32.12l-4-1.81a.25.25,0,0,1-.12-.32L62.11,19a.23.23,0,0,1,.31-.12l4,1.8a.25.25,0,0,1,.12.32L66,22.13a.23.23,0,0,1-.31.12l-2.55-1.16a.12.12,0,0,0-.16.06l-.59,1.31a.11.11,0,0,0,.06.16l2.46,1.12a.23.23,0,0,1,.12.31l-.5,1.1a.25.25,0,0,1-.32.12l-2.45-1.12a.12.12,0,0,0-.16.06Z'
          fill='#fff'
        />
        <path
          d='M68.84,31a.29.29,0,0,1-.21-.32l.25-4.5c0-.08-.08-.09-.11,0L67.06,30a.24.24,0,0,1-.32.12l-1.09-.49a.24.24,0,0,1-.12-.32l3.27-7.21a.23.23,0,0,1,.31-.12l1.3.59a.29.29,0,0,1,.19.32l-.39,4.83c0,.08.08.09.11,0l1.86-4.1a.24.24,0,0,1,.32-.12l1.09.49a.24.24,0,0,1,.12.32l-3.27,7.21a.23.23,0,0,1-.31.12Z'
          fill='#fff'
        />
        <path
          d='M78.12,28,75.5,33.8a.25.25,0,0,1-.32.12l-1.09-.5A.24.24,0,0,1,74,33.1l2.62-5.79a.1.1,0,0,0-.06-.15l-1.37-.62a.25.25,0,0,1-.12-.32l.5-1.09a.24.24,0,0,1,.32-.12l4.48,2a.23.23,0,0,1,.12.31l-.5,1.1a.25.25,0,0,1-.32.12L78.28,28A.11.11,0,0,0,78.12,28Z'
          fill='#fff'
        />
        <path
          d='M81.94,33.29a.12.12,0,0,0-.16.06l-1.23,2.73a.24.24,0,0,1-.32.12l-1.09-.49a.24.24,0,0,1-.12-.32l3.26-7.21a.25.25,0,0,1,.32-.12L85,29.15a2.53,2.53,0,0,1,1.26,3.33,2.37,2.37,0,0,1-2,1.42c-.21,0-.23.14-.23.33L84,37.5a.24.24,0,0,1-.36.23l-1.12-.51a.35.35,0,0,1-.22-.36l.11-3.21a.24.24,0,0,0-.13-.23Zm1.39-3.05a.11.11,0,0,0-.16.05l-.59,1.32a.1.1,0,0,0,.06.15l1,.45a.84.84,0,0,0,1.11-.42.85.85,0,0,0-.42-1.11Z'
          fill='#fff'
        />
        <path
          d='M88.4,37.93a.12.12,0,0,0,.06.16l2.59,1.18a.23.23,0,0,1,.12.31l-.49,1.09a.24.24,0,0,1-.32.12l-4-1.81a.25.25,0,0,1-.12-.32l3.27-7.21a.25.25,0,0,1,.32-.12l4,1.8a.25.25,0,0,1,.12.32l-.49,1.09a.25.25,0,0,1-.32.12L90.54,33.5a.12.12,0,0,0-.16.06l-.59,1.31a.11.11,0,0,0,.06.16l2.46,1.12a.23.23,0,0,1,.12.31l-.5,1.1a.24.24,0,0,1-.32.12l-2.45-1.12a.12.12,0,0,0-.16.06Zm6.13-7.23c.2.09.17.28,0,.35l-1.85.76a.45.45,0,0,1-.39,0l-.75-.34a.21.21,0,0,1,0-.39L93,30.22a.35.35,0,0,1,.37,0Z'
          fill='#fff'
        />
        <path
          d='M95.09,41a.12.12,0,0,0,.06.16l2.59,1.18a.23.23,0,0,1,.12.31l-.49,1.1a.25.25,0,0,1-.32.12L93,42a.25.25,0,0,1-.12-.32l3.27-7.21a.23.23,0,0,1,.31-.12l4,1.8a.25.25,0,0,1,.12.32l-.5,1.09a.23.23,0,0,1-.31.12l-2.55-1.16a.13.13,0,0,0-.16.06l-.59,1.31a.11.11,0,0,0,.06.16L99,39.18a.23.23,0,0,1,.12.31l-.5,1.1a.25.25,0,0,1-.32.12l-2.45-1.12a.12.12,0,0,0-.16.06Z'
          fill='#fff'
        />
      </g>
      <path
        d='M34.4,65.16a.48.48,0,0,0-.91,0l-2.71,6.73a.89.89,0,0,1-1.65,0l-1.78-3.82a2.47,2.47,0,0,1-.18-2l2.29-7.12A3,3,0,0,1,31,57.06L35.42,55a.88.88,0,0,1,1.17.42L46.5,76.7a.41.41,0,0,0,.58.21l4.42-2a.86.86,0,0,1,1.16.42l1.76,3.78A.87.87,0,0,1,54,80.22l-16.3,7.6a.88.88,0,0,1-1.17-.42l-1.76-3.78a.89.89,0,0,1,.43-1.17l5.56-2.59a.41.41,0,0,0,.22-.58Z'
        fill='#fff'
      />
      <path
        d='M48.35,54.4,51,51.2a.87.87,0,0,1,1.23-.12c1.53,1.24,3.36.25,4.55-1.22,1.67-2,.95-4.21-1.1-5.87-1.73-1.41-4.51-2.38-6.31-.17A3.52,3.52,0,0,0,48.59,47a1.16,1.16,0,0,1-.29,1.12l-2.69,3.3a.87.87,0,0,1-1.23.13l-12.25-10A.88.88,0,0,1,32,40.29L42,28a.87.87,0,0,1,1.23-.13l3.27,2.66a.87.87,0,0,1,.12,1.23L40,39.88a.41.41,0,0,0,.06.61l3.17,2.58c.44.36.78.08.83-.4a5.45,5.45,0,0,1,1.3-2.78c3.36-4.11,9.19-4.4,14-.49,4.12,3.35,5.92,9.4,2,14.23-3.24,4-8.3,5.74-12.89,2A.83.83,0,0,1,48.35,54.4Z'
        fill='#fff'
      />
      <path
        d='M87.54,60.62l-.09.65a.88.88,0,0,1-1,.74l-4.38-.65a.88.88,0,0,1-.74-1l.1-.65c.25-1.73-.91-3.46-3.38-3.82s-4,1-4.26,2.69,1.24,2.71,3.43,3.48c5.85,2.11,10,5.29,9.06,11.32a9.25,9.25,0,0,1-8.55,8c-.45.07-.69.16-.75.55l-.22,1.48a.87.87,0,0,1-1,.74l-3.69-.55a.88.88,0,0,1-.74-1l.23-1.52c.05-.39-.23-.57-.6-.76a9.49,9.49,0,0,1-5.61-10l.19-1.3a.88.88,0,0,1,1-.74l4.38.65a.88.88,0,0,1,.74,1l-.19,1.31c-.3,2,1,3.78,3.68,4.18s4.52-.88,4.82-2.92c.28-1.87-.85-2.92-4-4.14-5.25-2-9.19-5-8.36-10.64A9.08,9.08,0,0,1,75.46,50c.41-.07.73-.15.79-.54l.23-1.57a.89.89,0,0,1,1-.74l3.68.55a.88.88,0,0,1,.74,1l-.21,1.47c-.06.39.17.6.54.79A9.17,9.17,0,0,1,87.54,60.62Z'
        fill='#fff'
      />
      <g>
        <path
          d='M10.86,59.54a2.52,2.52,0,1,1,4.69,1.85l-.4,1a.12.12,0,0,0,.07.16L18,63.65a.24.24,0,0,1,.14.31l-.44,1.11a.24.24,0,0,1-.32.14L10,62.31A.25.25,0,0,1,9.9,62ZM12,61.16a.11.11,0,0,0,.07.16l1.34.52a.11.11,0,0,0,.15-.06l.4-1a.85.85,0,0,0-.48-1.09.86.86,0,0,0-1.09.48Z'
          fill='#fff'
        />
        <path
          d='M19.31,56.1a.12.12,0,0,0,.16-.07l.92-2.34a.24.24,0,0,1,.31-.14l1.12.44a.24.24,0,0,1,.13.31l-1.49,3.8a.25.25,0,0,1-.32.13l-7.36-2.9a.24.24,0,0,1-.13-.31l.44-1.12a.23.23,0,0,1,.31-.13Z'
          fill='#fff'
        />
        <path
          d='M22.78,48.33a.2.2,0,0,0-.23.11l-.67,1.69c0,.1,0,.17.1.24l1,.76a.23.23,0,0,1,.08.28l-.46,1.17c-.07.18-.22.2-.31.14l-6.63-4.93a.21.21,0,0,1-.09-.27l.59-1.5a.24.24,0,0,1,.25-.15l8.2.94a.21.21,0,0,1,.14.31l-.46,1.18a.23.23,0,0,1-.25.14Zm-2-.32-2.89-.52s-.05,0-.06,0v0s0,.05,0,.07l2.47,1.59c.08.06.13,0,.15,0l.41-1C20.89,48.08,20.87,48,20.77,48Z'
          fill='#fff'
        />
        <path
          d='M20.32,40.46l5.91,2.33a.25.25,0,0,1,.14.31l-.44,1.12a.25.25,0,0,1-.32.13L19.7,42a.12.12,0,0,0-.16.07L19,43.49a.23.23,0,0,1-.31.13l-1.11-.44a.24.24,0,0,1-.14-.31l1.81-4.58a.23.23,0,0,1,.31-.13l1.11.44a.24.24,0,0,1,.14.31l-.55,1.4A.11.11,0,0,0,20.32,40.46Z'
          fill='#fff'
        />
      </g>
      <g>
        <path
          d='M44.78,98.48a2.81,2.81,0,0,1,3.16,2,13.61,13.61,0,0,1,.63,3,2.8,2.8,0,0,1-2.07,3.14l-2.47.52a.25.25,0,0,1-.28-.19l-1.62-7.75a.24.24,0,0,1,.18-.28Zm-.6,1.84a.12.12,0,0,0-.09.14l1,4.7a.12.12,0,0,0,.15.09l.93-.19a1,1,0,0,0,.77-1.16,13,13,0,0,0-.64-3,1,1,0,0,0-1.16-.75Z'
          fill='#fff'
        />
        <path
          d='M53,103.51a.13.13,0,0,0,.15.1l2.78-.59a.24.24,0,0,1,.28.19l.25,1.17a.25.25,0,0,1-.19.29l-4.31.9a.25.25,0,0,1-.28-.19L50,97.63a.24.24,0,0,1,.19-.28l4.26-.89a.24.24,0,0,1,.28.18L55,97.82a.24.24,0,0,1-.19.28l-2.73.57a.13.13,0,0,0-.1.15l.3,1.41a.11.11,0,0,0,.14.09l2.64-.55a.23.23,0,0,1,.28.18l.25,1.18a.24.24,0,0,1-.19.28l-2.64.55a.12.12,0,0,0-.09.14Z'
          fill='#fff'
        />
        <path
          d='M60.49,100.25c-1.54,0-2.84-.43-3.15-1.94a2.76,2.76,0,0,1,5.38-1.13l0,.18a.24.24,0,0,1-.19.28l-1.18.25a.23.23,0,0,1-.28-.19l0-.17a1,1,0,0,0-1.23-.67c-.66.14-1,.57-.85,1s.59.64,1.22.63c1.7,0,3,.41,3.39,2a2.66,2.66,0,0,1-2.32,3.17,2.69,2.69,0,0,1-3.33-2l-.08-.35a.26.26,0,0,1,.19-.29l1.18-.25a.26.26,0,0,1,.29.19l.07.35a1,1,0,0,0,1.34.73c.71-.15,1.08-.66,1-1.21S61.41,100.26,60.49,100.25Z'
          fill='#fff'
        />
        <path
          d='M68,98.67c-1.54,0-2.84-.43-3.15-1.94a2.76,2.76,0,0,1,5.38-1.13l0,.18a.23.23,0,0,1-.18.28l-1.19.25a.23.23,0,0,1-.28-.18l0-.18a1,1,0,0,0-1.23-.66c-.66.13-.95.56-.85,1s.59.64,1.22.63c1.7,0,3,.41,3.4,2a2.9,2.9,0,0,1-5.66,1.18l-.08-.35a.26.26,0,0,1,.19-.29l1.18-.24a.24.24,0,0,1,.29.18l.07.35a1,1,0,0,0,1.34.73c.71-.15,1.08-.66,1-1.21S69,98.68,68,98.67Z'
          fill='#fff'
        />
        <path
          d='M75.22,98.85a.13.13,0,0,0,.15.1l2.78-.58a.23.23,0,0,1,.28.18l.25,1.17a.25.25,0,0,1-.19.29l-4.31.9a.24.24,0,0,1-.28-.19L72.28,93a.25.25,0,0,1,.19-.29l4.26-.89A.24.24,0,0,1,77,92l.25,1.17a.24.24,0,0,1-.19.28L74.34,94a.12.12,0,0,0-.1.14l.3,1.41a.11.11,0,0,0,.14.09l2.64-.55a.23.23,0,0,1,.28.18l.25,1.18a.24.24,0,0,1-.19.28L75,97.3a.12.12,0,0,0-.09.15Z'
          fill='#fff'
        />
        <path
          d='M82.21,95.8a.11.11,0,0,0-.09.14l.61,2.94a.23.23,0,0,1-.18.28l-1.18.25a.25.25,0,0,1-.28-.19l-1.62-7.75a.23.23,0,0,1,.18-.28l2.59-.54a2.53,2.53,0,0,1,3,1.95,2.38,2.38,0,0,1-.79,2.33c-.14.15-.11.24,0,.4L86.31,98a.23.23,0,0,1-.15.39L85,98.66a.35.35,0,0,1-.39-.17l-1.8-2.65a.22.22,0,0,0-.24-.1Zm-.69-3.29a.12.12,0,0,0-.09.14l.3,1.41a.12.12,0,0,0,.14.1l1-.22a.84.84,0,0,0-.34-1.65Z'
          fill='#fff'
        />
        <path
          d='M90,90.86l1.3,6.22a.24.24,0,0,1-.19.28L90,97.61a.25.25,0,0,1-.29-.19l-1.3-6.22a.11.11,0,0,0-.14-.09l-1.47.31a.25.25,0,0,1-.28-.19l-.25-1.17a.26.26,0,0,1,.19-.29l4.81-1a.24.24,0,0,1,.29.18l.24,1.18a.23.23,0,0,1-.18.28l-1.47.31A.11.11,0,0,0,90,90.86Z'
          fill='#fff'
        />
      </g>
    </g>
  </svg>
);

export default LogoBouillonPrice;
